import {Directive, Renderer2, Input, OnInit, ElementRef, HostListener, AfterViewInit} from '@angular/core';

@Directive({
  selector: '[helper]'
})
export class HelperDirective implements AfterViewInit {
  el: ElementRef;
  @Input('text') text: any;
  @Input('description') description: string;
  html: any;

  constructor(el: ElementRef, private renderer: Renderer2
  ) {
    this.el = el;
  }

  generateHtml(text, description) {
    this.renderer.addClass(this.el.nativeElement, 'help');
    text = this.el.nativeElement.innerText;
    var html = text + ' <span class="text-info fa fa-question-circle" title="' + description + '"></span>';
    this.html = html;
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.html);
  }

  ngAfterViewInit(): void {
    this.generateHtml(this.text, this.description);
  }
}
