import {Authentication} from "../../login/Authentication";
import {HttpClient, HttpHeaders} from "@angular/common/http";

export class BaseService {
  token: string;
  progress$: any;
  progress: any;
  progressObserver: any;
  options: any;
  constructor(public http: HttpClient) {
    var currentUser = null;
    if (localStorage.getItem('Authentication') !== 'undefined') {
      currentUser = JSON.parse((localStorage.getItem('Authentication'))) as Authentication;
      if (currentUser && currentUser.token) {
        this.token = 'Bearer ' + currentUser.token;
        let headers = new HttpHeaders({
          'Content-Request': 'application/json',
          'content-type': 'application/json',
          'Authorization': this.token,
          'Access-Control-Allow-Origin': '*'
        });
        this.options = {headers: headers};
      }
    }
  }
}
