import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import * as globals from '../../../helpers/globals';
import 'rxjs/add/operator/map';
import {BaseService} from "../../generic/services/baseService";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {api} from "../../../helpers/globals";
@Injectable()
export class UsersService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }


  Save(model: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Request': 'application/json','content-type':'application/json','Authorization':this.token,'Access-Control-Allow-Origin':'*' });
    let options = { headers: headers};
    let body = JSON.stringify(model);
    return this.http.post(globals.api +'User/Create', body, options).map(res => res);
  }
  Update(model: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Request': 'application/json','content-type':'application/json','Authorization':this.token,'Access-Control-Allow-Origin':'*' });
    let options = { headers: headers};
    let body = JSON.stringify(model);
    return this.http.put(globals.api +'User/UpdateByAdmin', body, options).map(res => res);
  }
  Information(model: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Request': 'application/json','content-type':'application/json','Authorization':this.token,'Access-Control-Allow-Origin':'*' });
    let options = { headers: headers};
    let body = JSON.stringify(model);
    return this.http.post(globals.api +'Information', body,options).map(res => res);
  }
  
  FindAll(): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Request': 'application/json','content-type':'application/json','Authorization':this.token,'Access-Control-Allow-Origin':'*' });
    let options = { headers: headers};
    return this.http.post(api +'FindAll',null,this.options);
  }
  FindAllRole(model: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Request': 'application/json','content-type':'application/json','Authorization':this.token,'Access-Control-Allow-Origin':'*' });
    let options = { headers: headers};
    let body = JSON.stringify(model);
    return this.http.post(globals.api +'Roles/FindAll', body,options).map(res => res);
  }
  changePassword(model: any): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Request': 'application/json','content-type':'application/json','Authorization':this.token,'Access-Control-Allow-Origin':'*' });
    let options = { headers: headers};
    let body = JSON.stringify(model);
    return this.http.post(globals.api +'User/changePassword', body, options).map(res => res);
  }

  Delete(id: any): Observable<any> {
    return this.http.delete(globals.api +'DeleteByAdmin/'+ id,this.options).map(res => res);
  }

  Logout() {
    let options = {
      headers: new HttpHeaders({
        'Authorization':this.token,
        'Accept':'text/plain'
      }),
      'responseType': 'text' as 'json'
    }
    return this.http.post(globals.api +'logout',null, options);
  }
  Activate(id: number): Observable<any> {
    return this.http.post(api + 'User/Activate/' + id,null, this.options);
  }

  Deactivate(id: number): Observable<any> {
    return this.http.post(api + 'User/Deactivate/' + id,null, this.options);
  }
}


