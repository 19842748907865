<div class="LoginScreen">

  <div class="Content">
    <div class="header">
      <div class="logo">
        <img src="assets/images/logo.png">
        <span class="text-white font-weight-bold">Body</span>
      </div>
    </div>

    <nb-auth-block>
      <!--<h2 class="title"></h2>-->
      <small class="form-text sub-title">{{'Sign In Form'}}</small>

      <form #form="ngForm" (ngSubmit)="login()" autocomplete="nope">

        <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger">
          <div><strong>Sorry !</strong></div>
          <div *ngFor="let error of errors">{{ error }}</div>
        </nb-alert>

        <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success">
          <div><strong>Very Well!</strong></div>
          <div *ngFor="let message of messages">{{ message }}</div>
        </nb-alert>

        <div class="form-group">
          <label class="sr-only" for="input-email">{{"Username"}}</label>
          <!--<input nbInput-->
          <!--[(ngModel)]="user.email"-->
          <!--#email="ngModel"-->
          <!--name="username"-->
          <!--id="input-username"-->
          <!--placeholder="Username"-->
          <!--autofocus-->
          <!--fullWidth>-->
          <input #email="ngModel"
                 [(ngModel)]="user.username"
                 [required]="getConfigValue('forms.validation.email.required')"
                 [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
                 fullWidth
                 id="input-email"
                 name="email"
                 nbInput
                 pattern=""
                 placeholder="{{'Username'}}">
          <small *ngIf="email.invalid && email.touched && email.errors?.required" class="form-text error">
            Username is required!
          </small>
          <small *ngIf="email.invalid && email.touched && email.errors?.pattern"
                 class="form-text error">
            Email should be the real one!
          </small>
        </div>

        <div class="form-group">
          <label class="sr-only" for="input-password">{{"Password"}}</label>
          <input #password="ngModel"
                 [(ngModel)]="user.password"
                 [maxlength]="getConfigValue('forms.validation.password.maxLength')"
                 [minlength]="2"
                 [required]="getConfigValue('forms.validation.password.required')"
                 [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
                 fullWidth
                 id="input-password"
                 name="password"
                 nbInput
                 placeholder="{{'Password'}}"
                 type="password">
          <small *ngIf="password.invalid && password.touched && password.errors?.required" class="form-text error">
            Password is required!
          </small>
          <small
            *ngIf="password.invalid && password.touched && (password.errors?.minlength || password.errors?.maxlength)"
            class="form-text error">
            Password should contains
            from {{ getConfigValue('forms.validation.password.minLength') }}
            to {{ getConfigValue('forms.validation.password.maxLength') }}
            characters
          </small>
        </div>

        <div class="form-control-group accept-group">
          <nb-checkbox *ngIf="rememberMe" [(ngModel)]="user.RememberMe"
                       name="rememberMe">{{"Remember Me"}}
          </nb-checkbox>
          <a class="forgot-password" routerLink="../request-password"
             style="float: right">{{"Forgot_Password"}}?</a>
        </div>

        <button [class.btn-pulse]="submitted"
                [disabled]="submitted || !form.valid"
                fullWidth
                nbButton
                status="success">
          {{"Sign In"}}
        </button>
      </form>

      <div class="links">

        <ng-container *ngIf="socialLinks && socialLinks.length > 0">
          <small class="form-text">Or connect with:</small>

          <div class="socials">
            <ng-container *ngFor="let socialLink of socialLinks">
              <a *ngIf="socialLink.link"
                 [attr.class]="socialLink.icon"
                 [attr.target]="socialLink.target"
                 [class.with-icon]="socialLink.icon"
                 [routerLink]="socialLink.link">{{ socialLink.title }}</a>
              <a *ngIf="socialLink.url"
                 [attr.class]="socialLink.icon"
                 [attr.href]="socialLink.url"
                 [attr.target]="socialLink.target"
                 [class.with-icon]="socialLink.icon">{{ socialLink.title }}</a>
            </ng-container>
          </div>
        </ng-container>
<!--        <small class="form-text">-->
<!--                   {{"Dont_Have_Account"}}? <a routerLink="../auth/register"><strong>{{"Register_As_Customer"}}</strong></a>-->
<!--        </small>-->
      </div>
    </nb-auth-block>

  </div>

</div>





