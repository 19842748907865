import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {Authentication} from "../../../pages/login/Authentication";
import {UserDto} from "../../../pages/users/models/userDto";
import {UsersService} from "../../../pages/users/services/users.service";
import {NotificationService} from "../../../pages/notification/services/notification.service";
import {NotificationDto} from "../../../pages/notification/models/notification";
import {Router} from "@angular/router";
import {fileUploadBase, SharedService} from "../../../helpers/globals";
import {NotificationDtos} from "../../../pages/notification/models/notificationDtos";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  providers: [UsersService, NotificationService, TranslateService],
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'dark';

  @Input() position = 'normal';
  imgSrc:any;
  user: any;

  gettime:string = "";
  public notifications: NotificationDto[];

  userMenu = [{ title: 'Profile', data: 'profile' }, { title: 'Sign Out', data: 'logout' }];
  logoutFlag = false;
  private currentAuth: Authentication;
  modalService: any;
  showNotValidMessage: boolean;
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private userApi:UsersService,
              private notificationApi: NotificationService,
              private sharedService: SharedService,
              public translate: TranslateService,
              private breakpointService: NbMediaBreakpointsService, private router: Router, private ngZone: NgZone) {
    translate.setDefaultLang('dk');
    translate.use('dk');
    if (localStorage.getItem("theme")){

      this.currentTheme = localStorage.getItem("theme");
    }
    this.imgSrc = fileUploadBase;
    //const token = localStorage.setItem('Authentication', JSON.stringify(auth));
    this.notifications = new Array<NotificationDto>();
    notificationApi.FindPendings(null).subscribe(res=>{
      const dtos = res as NotificationDtos;
      this.notifications = dtos.Notifications;

    });

    setInterval(()=>{
      notificationApi.FindPendings(null).subscribe(res=>{
        const dtos = res as NotificationDtos;
        this.notifications = dtos.Notifications;
      });
    },1000 * 60);
    this.notifications = new Array<NotificationDto>();
    this.logoutFlag = true;
    this.currentAuth = (JSON.parse((localStorage.getItem('Authentication'))) as Authentication)
    this.menuService.onItemClick()
      .subscribe(x=> {
        if (x.item.data === 'profile'){
          this.ngZone.run(() => this.router.navigateByUrl('/pages/profile'));
        }
        if (this.logoutFlag) {
          if (x.item.data === 'logout') {
            userApi.Logout().subscribe(res=>{
              this.menuService.collapseAll();
              this.logoutFlag = true;
              localStorage.removeItem('Authentication');
              this.ngZone.run(() => this.router.navigateByUrl('/auth'));
            },error2 => {
              this.menuService.collapseAll();
              this.logoutFlag = true;
              localStorage.removeItem('Authentication');
              this.ngZone.run(() => this.router.navigateByUrl('/auth'));
            });

          }
        }
      })
  }

  ngOnInit() {

    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    let currentAuth = (JSON.parse((localStorage.getItem('Authentication'))) as Authentication);
    {
      let currentUser = this.sharedService.currentUser;
      this.user = {name:currentUser.displayName,picture:'assets/images/logo.png'}
    }

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    localStorage.setItem("theme",themeName);
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  setNotificationsEmpty(){
    this.notifications = [];
  }

  showNotification(model: NotificationDto)
  {
    this.notificationApi.Checked(model).subscribe(res =>{
      this.notifications.splice(this.notifications.indexOf(model), 1);
    });
    switch (model.Type){
      case "RequestTask":
        this.router.navigate(['/pages/schedule/scheduleSchedule']);
        break;
      default:
        break;
    }
  }
}
