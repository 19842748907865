<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()" style="display: flex">        <img style="height: 46px;align-self: baseline" src="assets/images/logo.png">
      <span style="align-self: center">Body</span></a>
  </div>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="small">

    <!--    <nb-action class="control-item">-->
    <!--      <nb-search type="rotate-layout"></nb-search>-->
    <!--    </nb-action>-->
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline" [disabled]="notifications.length ===0" badgeText="{{notifications.length}}"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [onlyPicture]="userPictureOnly" [nbContextMenu]="userMenu" [name]="user?.name" [picture]="user?.picture"></nb-user>

    </nb-action>
  </nb-actions>
</div>
<ng-template #card>
  <nb-card class="popover-card" style="width: 300px;height: 300px;">
    <nb-card-header status="warning">
      {{'Notifications'}}
    </nb-card-header>
    <nb-card-body>
      <div class="msg-list">
        <a *ngFor="let noti of notifications" class="clearfix" style="cursor: pointer;"
           (click)="showNotification(noti)">

          <div class="row" [hidden]="noti.IsChecked">
            <div class="col-lg-12">
              <div class="alert alert-primary" role="alert">
                <div class="row vertical-align">
                  <div class="col-xs-1 text-center">
                    <i class="fa" [ngClass]="noti.Icon">&nbsp;</i>
                  </div>
                  <div class="col-xs-11">
                    {{ noti.Label }} {{noti.CreatedDate  | date: 'dd/MM-yy HH:mm:ss'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <a (click)="setNotificationsEmpty()" href="pages/notification">{{'Show_All_Notifications'}}</a>
    </nb-card-body>
  </nb-card>
</ng-template>
