import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConfirmationPopoverModule} from "angular-confirmation-popover";
import {NgxSpinnerModule} from "./ngx-spinner/ngx-spinner.module";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ThemeModule} from "../../../@theme/theme.module";
import {NbCardModule, NbCheckboxModule} from '@nebular/theme';
import {HelperDirective} from "../directives/helper.directive";

@NgModule({
  imports: [
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'warning', // set defaults here
    }),
    NgxSpinnerModule,
    CommonModule,
    TranslateModule,
    ThemeModule,
    NbCardModule,
    NbCheckboxModule,
    ReactiveFormsModule,
  ],
  declarations: [
    HelperDirective,
  ],
  exports: [
    ReactiveFormsModule,
    ThemeModule,
    NbCardModule,
    TranslateModule,
    NbCheckboxModule,
    NgxSpinnerModule, ConfirmationPopoverModule, CommonModule, FormsModule, HelperDirective],
  providers: [TranslateService]
})
export class NgSharedModule {
}
