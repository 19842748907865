import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as globals from '../../helpers/globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class LoginService {
  constructor(private http: HttpClient) { }

  Authenticate(model: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const options = { headers: headers };
    const body = { userName: model.username, password: model.password };
    return this.http.post(globals.apiLogin + 'api/login', JSON.stringify(body), options);
  }
}
