import { Component } from '@angular/core';
import { NgModel} from '@angular/forms';

@Component({
  selector: `ngx-spinner`,
  template: `
    <div id="{{id}}" style="width: 100%;height: 100%;position:absolute;background:#00000066;z-index:100000000000">
      <!--<img src="/assets/img/loading.gif" style="width: 150px; height: 150px;position:absolute;left:0;right:0;top:0;bottom:0;margin:auto;"/>-->
      <div style="position: absolute; left:0;right:0;top:0;bottom:0;margin:auto;width:300px;height:300px;z-index: 10000">
      <lottie-animation-view
        [options]="lottieConfig"
        [width]="300"
        [height]="300"

        (animCreated)="handleAnimation($event)">
      </lottie-animation-view>
      </div>
    </div>
    `,
  styles: [],
  providers: [ NgModel ]
})
export class NgxSpinnerComponent{

  public id:string;
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed: number = 1;

  constructor() {
    this.id = this.guid();


    this.lottieConfig = {
      path: 'assets/super.json',
      autoplay: true,
      loop: true
    };

  }


  handleAnimation(anim: any) {
    this.anim = anim;
  }



  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }
}
