'use strict';
import {Authentication} from '../pages/login/Authentication';
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {UserDto} from "../pages/users/models/userDto";

export const api = 'https://api.sbody.ca/api/';
// export const api = 'https://localhost:7134/api/';
export const apiLogin = 'https://api.sbody.ca/';
// export const apiLogin = 'https://localhost:7134/';
export const fileUploadBase = apiLogin + 'Content/Blobs/';
export const fileUploadBaseHireRequest = apiLogin + 'Content/HireRequests/';
export const domain = "/";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public currentUser: UserDto;
  constructor() {
  }

}

