<nb-auth-block>
  <h2 class="title">Change password</h2>
  <small class="form-text sub-title">Please enter a new password</small>
  <form (ngSubmit)="resetPass()" #resetPassForm="ngForm">

    <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger">
      <div><strong>Oh snap!</strong></div>
      <div *ngFor="let error of errors">{{ error }}</div>
    </nb-alert>

    <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success">
      <div><strong>Hooray!</strong></div>
      <div *ngFor="let message of messages">{{ message }}</div>
    </nb-alert>

    <div class="form-group">
      <label for="input-password" class="sr-only">New Password</label>
      <input nbInput
             [(ngModel)]="user.password"
             #password="ngModel"
             type="password"
             id="input-password"
             name="password"
             class="first"
             placeholder="New Password"
             autofocus
             fullWidth
             [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
             [required]="getConfigValue('forms.validation.password.required')"
             [minlength]="getConfigValue('forms.validation.password.minLength')"
             [maxlength]="getConfigValue('forms.validation.password.maxLength')">
      <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required">
        Password is required!
      </small>
      <small
        class="form-text error"
        *ngIf="password.invalid && password.touched && (password.errors?.min || password.errors?.max)">
        Password should contains
        from {{getConfigValue('forms.validation.password.minLength')}}
        to {{getConfigValue('forms.validation.password.maxLength')}}
        characters
      </small>
    </div>

    <div class="form-group">
      <label for="input-re-password" class="sr-only">Confirm Password</label>
      <input nbInput
             [(ngModel)]="user.confirmPassword"
             #rePass="ngModel"
             id="input-re-password"
             name="rePass"
             type="password"
             class="last"
             placeholder="Confirm Password"
             fullWidth
             [status]="rePass.touched
                 ? (rePass.invalid || password.value !== rePass.value ? 'danger' : 'success')
                 : ''"
             [required]="getConfigValue('forms.validation.password.required')">
      <small class="form-text error"
             *ngIf="rePass.invalid && rePass.touched && rePass.errors?.required">
        Password confirmation is required!
      </small>
      <small
        class="form-text error"
        *ngIf="rePass.touched && password.value != rePass.value && !rePass.errors?.required">
        Password does not match the confirm password.
      </small>
    </div>

    <button nbButton
            status="success"
            fullWidth
            [disabled]="submitted || !resetPassForm.valid"
            [class.btn-pulse]="submitted">
      Change password
    </button>
  </form>

  <div class="links col-sm-12">
    <small class="form-text">
      Already have an account? <a routerLink="../login"><strong>Sign In</strong></a>
    </small>
    <small class="form-text">
      <a routerLink="../register"><strong>Sign Up</strong></a>
    </small>
  </div>
</nb-auth-block>
