import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxSpinnerComponent } from './components/index';
import {LottieAnimationViewModule} from "ng-lottie";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LottieAnimationViewModule.forRoot(),

  ],
  declarations: [
    NgxSpinnerComponent,
  ],
  exports: [
    NgxSpinnerComponent
  ]
})
export class NgxSpinnerModule {
}
